<template>
<div class="administrationanalytics-container">
    <vs-dialog blur prevent-close v-model="active">
        <template #header>
            <h4 class="not-margin">
                Esporta <b>statistiche</b>
            </h4>
        </template>

        <div class="con-form" style="display: flex; flex-direction: column; gap: 30px;">
            <vs-input type="date" :disabled="loading" v-model="start_date" label="Inizio periodo" block />
            <vs-input type="date" :disabled="loading" v-model="end_date" label="Fine periodo" block />
        </div>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="exportAnalytics" :loading="loading">
                    {{ $t('common.createNew') }}
                </vs-button>

            </div>
        </template>
    </vs-dialog>
</div>
</template>

<script>
import xlsx from "json-as-xlsx"

import {
    apiCall
} from '../../utils/ApiMiddleware';
export default ({
    data() {
        return {
            loading: false,
            start_date: '',
            end_date: '',
            active: true,
        }
    },
    props: {
        user: {
            type: Object,
            required: false
        }
    },

    mounted() {

    },

    methods: {

        async getAnalytics() {
            var d = new Date(this.end_date);
            // set time to 23:59:59
            d.setHours(23, 59, 59, 0);
            const response = await apiCall('GET', '/backoffice/metrics', {
                date_start: (new Date(this.start_date)).toISOString(),
                date_end: (d).toISOString(),
            });
            // if status code is 200, show a success message 
            if (response.status == 200) {
                return response.data;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Impossibile ottenere le statistiche.',
                    color: 'danger',
                    position: 'top-right'
                });
                return null;
            }
        },

        async exportAnalytics() {
            const metrics = await this.getAnalytics();
            if (metrics == null) return;

            let data = [{
                    sheet: "Utenti",
                    columns: [{
                        label: "Conteggio",
                        value: (row) => row.count
                    }, 
                    {
                        label: "Premium",
                        value: (row) => row.premium
                    },
                    ],
                    content: [{
                        count: metrics.users,
                        premium: metrics.users_with_subscription
                    }],
                },
                {
                    sheet: "Età",
                    columns: [{
                        label: "Fascia",
                        value: (row) => row.age
                    },{
                        label: "Conteggio",
                        value: (row) => row.count
                    }, ],
                    content: metrics.users_by_age_cluster != null ? metrics.users_by_age_cluster : [],
                },
                {
                    sheet: "Sesso",
                    columns: [{
                        label: "Maschile",
                        value: (row) => row.male
                    },{
                        label: "Femminile",
                        value: (row) => row.female
                    }, 
                    {
                        label: "Altro",
                        value: (row) => row.other
                    }, ],    
                    content: [{
                        male: metrics.male_users,
                        female: metrics.female_users,
                        other: metrics.other_users
                    }],
                },

                {
                    sheet: "Città",
                    columns: [{
                        label: "Città",
                        value: (row) => row.name
                    },{
                        label: "Conteggio",
                        value: (row) => row.count
                    }, ],
                    content: metrics.users_by_district != null ? metrics.users_by_district : [],
                },

                {
                    sheet: "Links",
                    columns: [{
                        label: "Descrizione",
                        value: (row) => row.description
                    },{
                        label: "URL",
                        value: (row) => row.short_link
                    },{
                        label: "Destinazione",
                        value: (row) => row.url
                    },{
                        label: "Conteggio",
                        value: (row) => row.count
                    }, ],
                    content: metrics.links_statistics != null ? metrics.links_statistics : [],
                },

                {
                    sheet: "Campagne",
                    columns: [{
                        label: "Descrizione",
                        value: (row) => row.description
                    },{
                        label: "URL",
                        value: (row) => row.short_link
                    },{
                        label: "Destinazione",
                        value: (row) => row.url
                    },{
                        label: "Registrazioni",
                        value: (row) => row.registered_users
                    },{
                        label: "Conversioni Premium",
                        value: (row) => row.bought_premium_users
                    }, ],
                    content: metrics.campaign_statistics != null ? metrics.campaign_statistics : [],
                },

                {
                    sheet: "Itinerari",
                    columns: [{
                        label: "Conteggio",
                        value: (row) => row.count
                    }, ],
                    content: [{
                        count: metrics.created_journeys
                    }],
                },

                // coupon_statistics (name, plan_name, usages)
                {
                    sheet: "Coupon",
                    columns: [{
                        label: "Nome",
                        value: (row) => row.name
                    },{
                        label: "Piano",
                        value: (row) => row.plan_name
                    },{
                        label: "Usi",
                        value: (row) => row.usages
                    }, ],
                    content: metrics.coupon_statistics != null ? metrics.coupon_statistics : [],
                },

            ]

            let settings = {
                fileName: "Tourial_Analytics_" + (new Date()).toISOString().split('.')[0], // Name of the resulting spreadsheet
                extraLength: 3, // A bigger number means that columns will be wider
                writeMode: "writeFile",
                writeOptions: {},
                RTL: false,
            }

            xlsx(data, settings) // Will download the excel file
        }

    },

    watch: {
        active(n) {
            if (!n) {
                this.$router.go(-1);
            }
        }
    }
})
</script>

<style scoped>
.administrationanalytics-container {
    padding-left: 70px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}
</style>
